
import CreateTabs from "@/components/Commons/CreateTabs.vue";
import Vue from "vue";
import { isEmpty, size } from "lodash";
import { TargetingSidebarNav } from "@/models/LineItem/Items";

export default Vue.extend({
  name: "NavTargeting",
  props: {
    current_tab: {
      type: Number,
      default: 0,
    },
    isGuaranteed: {
      type: Boolean,
      default: false,
    },
    targeting: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: { CreateTabs },
  data: () => ({}),
  created() {},
  async mounted() {},
  computed: {
    getItems() {
      const items = [
        new TargetingSidebarNav({
          key: 0,
          tab: this.$t("modifier.tabs.app_site"),
          disabled: false,
          show: true,
          count: this.getCountTargetingKeys("app_site"),
          src: require(`@/assets/icons/appsite_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 1,
          tab: this.$t("modifier.tabs.content"),
          disabled: this.isGuaranteed,
          show: true,
          count: this.getCountTargetingKeys("content"),
          src: require(`@/assets/icons/content_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 2,
          tab: this.$t("modifier.tabs.environment"),
          disabled: this.isGuaranteed,
          show: true,
          count: this.getCountTargetingKeys("environment"),
          src: require(`@/assets/icons/environment_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 3,
          tab: this.$t("modifier.tabs.exchange"),
          disabled: this.isGuaranteed,
          show: true,
          count: this.getCountTargetingKeys("exchange"),
          src: require(`@/assets/icons/exchange_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 4,
          tab: this.$t("modifier.tabs.geo"),
          disabled: this.isGuaranteed,
          show: true,
          count: this.getCountTargetingKeys("geo"),
          src: require(`@/assets/icons/geo_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 5,
          tab: this.$t("modifier.tabs.platform"),
          disabled: this.isGuaranteed,
          show: true,
          count: this.getCountTargetingKeys("platform"),
          src: require(`@/assets/icons/platform_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 6,
          tab: this.$t("modifier.tabs.time"),
          disabled: this.isGuaranteed,
          show: true,
          count: this.getCountTargetingKeys("time"),
          src: require(`@/assets/icons/time_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 7,
          tab: this.$t("modifier.tabs.user"),
          disabled: this.isGuaranteed,
          show: true,
          count: this.getCountTargetingKeys("user"),
          src: require(`@/assets/icons/user_icon.png`),
        }),

        new TargetingSidebarNav({
          key: 8,
          tab: "Summury",
          disabled: true,
          show: false,
          count: 0,
          src: "",
        }),
      ];

      return items;
    },
  },
  methods: {
    updateSelectedTabIndex(index: number) {
      this.$emit("update-selected-tab-index", index);
    },
    getCountTargetingKeys(key: any): Number {
      const tk = this.targeting[key];

      if (isEmpty(tk)) return 0;

      const values: Array<any> = Object.values(tk).filter((t: any) => {
        return !isEmpty(t.targeting_terms);
      });

      return size(values);
    },
  },
});
