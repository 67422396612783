
import { isString } from "lodash";
import Vue from "vue";
import CardTextField from "../../../../../../components/Content/CardTextField.vue";
import TermListUnique from "./termListUnique.vue";

export default Vue.extend({
	name: "TargetingTabItemSplit",
	props: {
		targeting_key_data: {
			type: Object,
			default: function() {
				return {};
			}
		},
		hint: {
			type: String,
			default: "Hint"
		},
		reference: {
			type: String,
			default: "reference"
		},
		placeholder: {
			type: String,
			default: "Placeholder"
		},
		label: {
			type: String,
			default: "Label"
		},
		persistent_hint: {
			type: Boolean,
			default: false
		},
		predicates: {
			type: Object,
			default: function() {
				return {};
			}
		},
		required: {
			type: Boolean,
			default: false
		},
		campaignPMP: {
			type: Boolean,
			default: false
		},
		messageRequire: {
			type: String,
			default: ""
		},
		readonly:{
			type:Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		disabled_switch: {
			type: Boolean,
			default: false
		},
	},
	components: {
		CardTextField,
		TermListUnique
	},
	data: () => ({}),
	async created() {},
	async mounted() {},
	computed: {},
	methods: {
		removeHandler(value: any) {
			this.$emit("remove-item", value);
		},
		clearHandler() {
			this.$emit("clear");
		},
		focusHandler() {
			this.$emit("focus");
		},
		blurHandler(data: string) {
			let items: Array<string> = [];

			if (isString(data)) {
				items = data.split(",");
			}

			this.$emit("blur", items);
		},

		enterHandler(e: any) {
			this.$emit("enter", e);
		}
	},

	watch: {}
});
