import Vue from "vue";
import AmchartCombineBulletGraph from "@/utils/amcharts/combine-bullet-graph";
import { isEmpty, orderBy } from "lodash";

const slug: string = "combine_Bullet_graph";
const DEFAULT_FORMAT_TIME = "HH:mm"; 

export default Vue.extend({
	name: "CombineBulletGraph",
	props: {
		source: {
			type: Array,
			default: function () {
				return [];
			},
		},
		loading: {
			type: Boolean,
			default: false
		},
		infoData: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	components: {},
	data: () => ({
		dinamic_id: "",
		paramsGraph:{
			valXAxis: "hora",
			valCol0: "win",
        	valCol1: "bid"
		}
		
	}),
	created() {
		this.$nextTick(async () => {
			this.dinamic_id = `${this.infoData.id}_${slug}`;
		});
	},
	async mounted() {
		try {
			await this.loadSource();
		} catch (error) {
			
		}
	},
	computed: {},
	methods: {
		async loadSource() {
			
			let data = this.source.map((v) => {
				return {
					hora: this.moment(v.hour).valueOf(),
					bid: Number(v.Bids),
					win: Number(v.Win_Rate)
				}
			});
			

			this.dinamic_id = `${this.infoData.id}_${slug}`;

			await AmchartCombineBulletGraph.dispose(this.dinamic_id);

			await AmchartCombineBulletGraph.setup({
				id: this.dinamic_id,
				infoData: this.infoData,
				source: data,
				valXAxis: this.paramsGraph.valXAxis,
				valCol0: this.paramsGraph.valCol0,
				valCol1: this.paramsGraph.valCol1,
				tooltipText: "[bold]Hora:[/bold] {hora}\n[bold]Bid:[/bold] {bid}\n[bold]Win Rate:[/bold] {win}",
			});
		},
	},
	watch: {
		async source(val) {
			this.loadSource();
		},
	},
});