import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
import Overview from "@/views/Admin/LineItem/Dependencies/Forms/Overview.vue";
import NavTargeting from "@/views/Admin/LineItem/Dependencies/Targetings/tab_contents/targetingNav.vue";
import SummaryTargeting from "@/views/Admin/LineItem/Dependencies/Targetings/tab_contents/targetingSummary.vue";
import Vue from "vue";
import { CampaingFilters, CampaingOptions } from "@/interfaces/campaign";

import ParamService from "@/services/params-service";

import { TargetingDataCreate, TargetingDataUpdate, Term } from "@/interfaces/targeting";

import { initDataVariables, getTargetingIDByValue, getLineItemResources } from "@/utils/initData";
import Alertize from "@/components/Alertize.vue";
import { find, isArray, isEmpty, isNull, isString, isUndefined, trim, filter, first, debounce, head } from "lodash";
import { LineItemDataCreate, LineItemDataUpdate, GraphicBidWin } from "@/interfaces/line_item";
import CardTextField from "@/components/Content/CardTextField.vue";
import AppSite from "@/views/Admin/LineItem/Dependencies/Targetings/appSite.vue";
import Content from "@/views/Admin/LineItem/Dependencies/Targetings/content.vue";
import Environment from "@/views/Admin/LineItem/Dependencies/Targetings/environment.vue";
import Geo from "@/views/Admin/LineItem/Dependencies/Targetings/geo.vue";
import Platform from "@/views/Admin/LineItem/Dependencies/Targetings/platform.vue";
import Exchange from "@/views/Admin/LineItem/Dependencies/Targetings/exchange.vue";
import User from "@/views/Admin/LineItem/Dependencies/Targetings/user.vue";
import Time from "@/views/Admin/LineItem/Dependencies/Targetings/time.vue";
import { prepareTargetingDataCreate } from "@/utils/resolveObjectArray";
import SkeletonLineItem from "@/views/Admin/LineItem/Dependencies/Forms/Skeleton.vue";
import { SortingOption } from "@/interfaces/paginated";
import CardActions from "@/components/Content/CardActions.vue";
import EventsContent from "@/views/Admin/LineItem/Dependencies/AssociationsEvents/AssociatedEvents.vue";

import NavModifiersModels from "@/views/Admin/Campaigns/Dependencies/TabNavigation.vue";
import BidModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModel.vue";
import BidModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModifier.vue";
import DeliveryModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModel.vue";
import DeliveryModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModifier.vue";
import { Notification, MessageTypes } from "@/interfaces/proccess";

import { mapActions, mapGetters } from "vuex";
import { parseToLower } from "@/utils/filter-global";
import { InitTargeting } from "@/models/tabs/Targeting";

import AssociationForm from "@/views/Admin/LineItem/Dependencies/Creatives/AssociationForm.vue";

import {
  getPermisionTargeting,
  getPermisionLineItem,
  getPermisionModifiers,
  getPermisionEvent,
} from "@/utils/permissionResolve";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { SnackNotificationEntity } from "@/models/Snack";
import { SnactType } from "@/interfaces/snack";
import { FormLineItem, FormPayload, FrequencyCap, ResourceFrequecyCap } from "@/models/LineItem/Form";
import { LINE_TAB_ITEMS, LineTabItem } from "@/models/LineItem/Items";
import LineItemDispatchMixin from "@/mixins/LineItem/Dispatch";
import { InitialRequiredResource } from "@/interfaces/LineItem/types";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
import { LINE_ITEM_DEFAULTS, STRATEGY_OPTIONS } from "@/mixins/LineItem/ResourceData";
import { sleep } from "@/utils/convert";

export default Vue.extend({
  name: "OverviewLineItem",
  mixins: [LineItemDispatchMixin],
  props: {},
  components: {
    CreateTabs,
    Overview,
    Alertize,
    NavTargeting,
    CardTextField,
    AppSite,
    Content,
    Environment,
    Geo,
    Exchange,
    User,
    Time,
    Platform,
    SkeletonLineItem,
    CardActions,
    NavModifiersModels,
    BidModelContent,
    BidModifierContent,
    DeliveryModelContent,
    DeliveryModifierContent,
    AssociationForm,
    SummaryTargeting,
    EventsContent,
    SnackMessage,
  },
  data: () => ({
    title: "OverviewLineItem",
    currentTab: 0,
    currentTabTargeting: 8,

    filters: {},
    options: { sort: "id", order: "desc" } as SortingOption,

    //Aux App Site
    data_variables: initDataVariables(),

    // Line Item Data
    line_item: new FormLineItem(),

    // Targeting Data
    targeting: new InitTargeting(),

    targeting_expressions: null,

    targeting_summary: [],

    resources: getLineItemResources(),

    updateCountBidModifier: true,

    // Tab Modifiers and Models
    currentTabModifiers: 0,

    loadTargeting: true,

    fieldLabel: {
      daily_budget: "Daily Budget",
      daily_budget_suggested: "Daily Budget Suggested",
      pacing_behavior_id: "Pacing Behaviour",
      catchup_behavior_id: "Catchup Behaviour",
      multiplier_pacing_behavior: "Multiplier Pacing Behaviour",
    },

    bid_win: {
      id: undefined,
      start_date: undefined,
      end_date: undefined,
    } as GraphicBidWin,

    dataGraphic: {
      data: null,
      period: [],
      loadingGraph: false,
    },

    updateCPMBid: true,

    flag_clac_cpm_bid: false,

    allows_events: false,

    retailer_isvalid: true,

    snack_notification: new SnackNotificationEntity(),

    renderComponent: true,
  }),
  async created() {
    this.$nextTick(async () => {
      this.setLineItem(new FormLineItem());
      await this.setSelectedCampaign();
    });
  },
  async mounted() {
    this.$nextTick(() => {
      this.onMountedResources();
    });
  },
  computed: {
    ...mapGetters("loading", ["isLoading", "getLoadingData"]),
    ...mapGetters("profile", ["isRolReport", "getAbility", "isAccountRM"]),

    getRetailers(): any[] {
      return this.$store.state.profile.account.retailers;
    },

    isReadOnly(): boolean {
      return this.isRolReport;
    },

    isCreateRoute() {
      return this.$route.path.endsWith("/create");
    },

    isEditRoute() {
      return /^\/.*\/edit\/\d+$/.test(this.$route.path);
    },

    getID(): Number {
      return Number(this.$route.params.id);
    },

    hasCampaignID(): boolean {
      return this.$route.query.campaign_id > 0;
    },

    getCampaignID(): number {
      return Number(this.$route.query.campaign_id);
    },

    hasTabIndex(): boolean {
      return this.$route.query.section > 0;
    },

    hasEventPermissions(): boolean {
      return this.allows_events;
    },

    getTabIndex(): number {
      return Number(this.$route.query.section);
    },

    getCreativeAssociations(): any[] {
      return this.line_item?.creative_associations || [];
    },

    getTargetingExpressions() {
      return this.$store.state.targeting.targeting_expressions;
    },
    getTargetingSummary() {
      return this.$store.state.targeting.targeting_summary;
    },
    getFrecuencyCapType() {
      return this.resources.types_frecuency_caps;
    },
    hasTargetingExpressionsID() {
      return this.hasData(this.getTargetingExpressions) && this.hasData(this.getTargetingExpressions?.id);
    },
    getTargetingExpressionsID() {
      return this.getTargetingExpressions?.id;
    },
    getItems(): LineTabItem[] {
      const disabledMap = {
        overview: false,
        targeting: this.cannotAccessTargeting,
        modifier: this.cannotAccessModifers,
        creative: this.cannotAccessCreatives,
        event: this.cannotAccessEvent,
      };

      const tabKeyMap: Record<string, keyof typeof disabledMap> = {
        "lineItem.tabs.overview": "overview",
        "lineItem.tabs.targeting": "targeting",
        "lineItem.tabs.modifier": "modifier",
        "lineItem.tabs.creative": "creative",
        "lineItem.tabs.event": "event",
      };

      const newItems = LINE_TAB_ITEMS.map(n => {
        const item = new LineTabItem(n);
        const key = tabKeyMap[item.tab];
        item.setDisabled(key ? disabledMap[key] : false);
        return item;
      });

      return newItems;
    },

    cannotAccessTargeting(): boolean {
      if (this.canTargeting && (this.canCreateTargeting || this.canUpdateTargeting)) {
        return !this.isEditRoute;
      }
      return false;
    },

    cannotAccessEvent() {
      if (this.isGuaranteed) return true;
      if (this.canEvent) {
        return !this.isEditRoute || !this.hasEventPermissions;
      }
      return false;
    },

    cannotAccessModifers() {
      if (this.isGuaranteed) return true;
      if (this.canCreateModifier || this.canUpdateModifier) {
        return !this.isEditRoute && !this.hasTargetingExpressionsID;
      }
      return false;
    },

    cannotAccessCreatives() {
      if (this.canAccessCreative && (this.canAccessCreativeCreate || this.canAccessCreativeUpdate)) {
        return !this.isEditRoute && !this.hasTargetingExpressionsID;
      }
      return false;
    },

    /**
     * End Resources
     */

    getErrors() {
      return this.$store.state.proccess.errors;
    },
    hasErrors() {
      return Object.keys(this.$store.state.proccess.errors).length > 0;
    },
    getPrepareTargetingDataCreate() {
      return prepareTargetingDataCreate(this.targeting);
    },
    isCreatedLineItem() {
      return this.hasData(this.line_item.id);
    },
    hasAssociatedCreatives() {
      return this.hasData(this.line_item.creative_associations) && !isEmpty(this.line_item.creative_associations);
    },
    getAssociatedCreatives(): Array<any> {
      return this.line_item.creative_associations;
    },
    hasTargetingExpressions() {
      return this.hasData(this.getTargetingExpressions) && !isEmpty(this.getTargetingExpressions);
    },
    isGuaranteed(): boolean {
      return this.line_item.guaranteed;
    },

    /**
     *
     */

    getSelectedCampaign() {
      return this.resources?.selected_campaign;
    },

    getOptimizationDescription() {
      return this.getSelectedCampaign?.optimization_strategy?.description.toLowerCase();
    },

    isCampaignOptimizationByLine() {
      return this.getOptimizationDescription === LINE_ITEM_DEFAULTS.OPTIMIZATION.BY_LINE;
    },

    isCampaignOptimizationByCampaign() {
      return this.getOptimizationDescription === LINE_ITEM_DEFAULTS.OPTIMIZATION.BY_CAMPAIGN;
    },

    getPredicateIds() {
      return {
        INCLUDED: this.isIncluded,
        EXCLUDED: this.isExcluded,
        REQUIRED: this.isRequired,
      };
    },

    isExcluded(): number | undefined {
      return this.getKeyMatchingResource(LINE_ITEM_DEFAULTS.PREDICATES.EXCLUDED, this.resources.targeting_predicates);
    },

    isIncluded(): number | undefined {
      return this.getKeyMatchingResource(LINE_ITEM_DEFAULTS.PREDICATES.INCLUDED, this.resources.targeting_predicates);
    },

    isRequired(): number | undefined {
      return this.getKeyMatchingResource(LINE_ITEM_DEFAULTS.PREDICATES.REQUIRED, this.resources.targeting_predicates);
    },

    isCampaignSelectedPMP(): boolean {
      return Boolean(this.getSelectedCampaign?.campaign_pmp);
    },

    canTargeting() {
      return this.getAbility.can(this.getPermissionTarget.actions.index, this.getPermissionTarget.subject);
    },

    canCreateTargeting() {
      return this.getAbility.can(this.getPermissionTarget.actions.create, this.getPermissionTarget.subject);
    },

    canUpdateTargeting() {
      return this.getAbility.can(this.getPermissionTarget.actions.update, this.getPermissionTarget.subject);
    },

    canEvent() {
      return this.getAbility.can(this.getPermissionLine.actions.event_asso, this.getPermissionLine.subject);
    },

    canCreateModifier() {
      return (
        this.getAbility.can(
          this.getPermissionModifier.subComponent.bid_modifier.create_bid,
          this.getPermissionModifier.subject,
        ) ||
        this.getAbility.can(
          this.getPermissionModifier.subComponent.delivery_modifier.create_delivery,
          this.getPermissionModifier.subject,
        )
      );
    },

    canUpdateModifier() {
      return (
        this.getAbility.can(
          this.getPermissionModifier.subComponent.bid_modifier.update_bid,
          this.getPermissionModifier.subject,
        ) ||
        this.getAbility.can(
          this.getPermissionModifier.subComponent.delivery_modifier.update_delivery,
          this.getPermissionModifier.subject,
        )
      );
    },

    canAccessCreative() {
      return this.getAbility.can(
        this.getPermissionLine.complement.actions.index_assoc,
        this.getPermissionLine.complement.subject,
      );
    },

    canAccessCreativeCreate() {
      return this.getAbility.can(
        this.getPermissionLine.complement.actions.create_assoc,
        this.getPermissionLine.complement.subject,
      );
    },

    canAccessCreativeUpdate() {
      return this.getAbility.can(
        this.getPermissionLine.complement.actions.update_assoc,
        this.getPermissionLine.complement.subject,
      );
    },

    canAccessActiveLineItem() {
      return this.getAbility.can(this.getPermissionLine.actions.active, this.getPermissionLine.complement.subject);
    },

    getPermissionTarget() {
      return getPermisionTargeting();
    },

    getPermissionLine() {
      return getPermisionLineItem();
    },

    getPermissionModifier() {
      return getPermisionModifiers();
    },

    getPermissionEvents() {
      return getPermisionEvent();
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("proccess", ["setLoadingField"]),
    ...mapActions("line_item", ["dataGraphicBidWin", "periodGraphicBidWin", "setLine"]),

    async onMountedResources() {
      try {
        this.clearError();

        await this.loadAllRequiredResources();

        const shouldSetCampaign: boolean = this.isCreateRoute && this.hasCampaignID;

        // Obtener y setear datos de la campaña por el parametro `campaign_id`
        if (shouldSetCampaign) {
          await this.setConfigFieldsWhenHasCampaignId(this.getCampaignID);
        }

        // Procesar datos en la edicion
        else if (this.isEditRoute) {
          const lineItemId: number = this.getID;
          this.setLoadingData(TypeLoading.loading);
          await this.fetchAndSetLineItem(lineItemId);
          await this.setLoadingData();
          this.loadGraphicBidWinData(lineItemId);
          this.setConfigFieldsWhenEditMode();
        }

        await this.setDefaultDataFields(this.isEditRoute || this.hasCampaignID);
        await this.loadFrecuencyType();
        this.setDurationDataFields();
      } catch (error) {
        console.error(`Logic::onMountedResources]`, { error });
      } finally {
      }
    },

    /**
     * Cargar los recursos requeridos al cargar el componente
     */
    async loadAllRequiredResources() {
      const resources: InitialRequiredResource = await this.loadRequiredResources();
      Object.assign(this.resources, resources);
    },

    /**
     * Obtiene un LineItem por su ID.
     * Setea los datos de line item
     * @param id
     * @returns
     */
    async fetchAndSetLineItem(id: number): Promise<FormLineItem> {
      const lineItem: FormLineItem = await this.fetchLineItemById(id);
      await this.setDataLineItem(lineItem);
      return lineItem;
    },

    async setDataLineItem(lineItem: FormLineItem): Promise<FormLineItem> {
      await this.setFilters(lineItem.id);
      await this.setLineItem(lineItem);
      await this.setLine(lineItem);
      await this.setSelectedCampaign(lineItem?.campaign);
      await this.applyCampaignSelectedToLineItem(this.hasCampaignID);
      await this.setDefaultDataFields(true);
      await this.updateCtrVcr();
      return lineItem;
    },

    /**
     * Obtiene una campaña por su ID.
     * @param id
     * @returns
     */
    async fetchCampaignByIdAndSetLineItem(id: number): Promise<FormLineItem> {
      const campaign: any = await this.fetchCampaignById(id);
      await this.setSelectedCampaign(campaign);
      await this.updateFieldsForCampaignPMP();
      await this.applyCampaignSelectedToLineItem(this.hasCampaignID);
      return campaign;
    },

    /**
     * Cargar datos para graficos de `bid_Win`
     * @param lineItemId
     */
    async loadGraphicBidWinData(lineItemId: number) {
      this.dataGraphic.period = await this.periodGraphicBidWin();
      this.bid_win.id = lineItemId;
      this.updateGraphic("1");
    },

    /**
     * Configura las configuraciones de cada campo en base a los datos de lineas
     */
    async setConfigFieldsWhenEditMode() {
      const lineItem: FormLineItem = this.line_item;
      const isByLine: Boolean = this.isCampaignOptimizationByLine;
      const isPacingDaily: Boolean = await this.isLinePacing(LINE_ITEM_DEFAULTS.LINE_PACING.DAILY);

      await this.applyCampaignSelectedToLineItem(this.hasCampaignID);

      this.setField("budget_type_id", {
        show: true,
        required: true,
        disabled: true,
        value: this.getSelectedCampaign?.budget_type_id,
      });

      this.setField("line_pacing_id", {
        show: isByLine,
        required: isByLine,
        disabled: !isByLine,
        value: lineItem?.line_pacing_id,
      });

      if (isPacingDaily) {
        this.setField("daily_budget", {
          show: isPacingDaily,
          required: isPacingDaily,
          disabled: !isPacingDaily,
          value: lineItem?.daily_budget,
        });

        const days = this.getCalculateDuration();

        this.setField("daily_budget_suggested", {
          show: isPacingDaily,
          required: false,
          disabled: true,
          value: days,
        });

        this.handleChange({ key: "line_duration", value: days });
      }

      await this.updateStrategies();
      await this.loadDeliveryBidding();
    },

    /**
     * Si en la query de la ruta existe `campaign_id`
     * Obtiene y setea los datos de la campaña (resource y line_item)
     */
    async setConfigFieldsWhenHasCampaignId(id: number) {
      await this.fetchCampaignByIdAndSetLineItem(id);
    },

    /**
     * Seteo de dato calculado para `line_duration`
     */
    async setDurationDataFields() {
      const days = this.getCalculateDuration();
      this.handleChange({ key: "line_duration", value: days });
    },

    /**
     * Seteo de datos por defecto `ramdom`
     */
    async setDefaultDataFields(validate: boolean = false) {
      const defaultCreativeMethodWeight = await this.getRamdomWeight();
      const defaultPacingBehaviourEven = await this.getPacingBehaviourEven();
      const defaultCatchupBehaviourEvent = await this.getCatchupBehaviourEven();

      if (!validate) {
        this.line_item.creative_method_id = defaultCreativeMethodWeight;
        this.line_item.pacing_behavior_id = defaultPacingBehaviourEven;
        this.line_item.catchup_behavior_id = defaultCatchupBehaviourEvent;
        return;
      }

      if (!this.line_item?.creative_method_id) {
        this.line_item.creative_method_id = defaultCreativeMethodWeight;
      }
      if (!this.line_item?.pacing_behavior_id) {
        this.line_item.pacing_behavior_id = defaultPacingBehaviourEven;
      }
      if (!this.line_item?.catchup_behavior_id) {
        this.line_item.catchup_behavior_id = defaultCatchupBehaviourEvent;
      }
    },

    clearError() {
      this.$store.state.proccess.errors = {};
    },

    async setIndexTab() {
      const tabIndex: any =
        !isUndefined(this.$route.query?.tabIndex) && Number(this.$route.query?.tabIndex) > -1
          ? Number(this.$route.query?.tabIndex)
          : undefined;

      if (tabIndex) {
        this.updateSelectedTabIndex(tabIndex);
      } else if (this.hasTabIndex) {
        this.updateSelectedTabIndex(this.getTabIndex);
      } else {
        this.updateSelectedTabIndex();
      }
    },

    handleCancelModifier() {
      this.$router.push({ name: "LineItemList" });
    },

    async updateBudgetDisplay() {
      const result = find(this.resources.budget_types, {
        id: this.line_item.budget_type_id,
      });

      if (result?.value == LINE_ITEM_DEFAULTS.BUDGET_TYPE.SPEND) {
        this.resources.budget_currency = this.getSelectedCampaign?.currency_key;
      } else {
        this.resources.budget_currency = undefined;
      }
      this.resources.budget_display = `Total ${result?.value || ""}`;
    },

    async updateStrategies() {
      const strategies = this.resources.strategies;

      const isVideo: Boolean = await this.isLineItemType(LINE_ITEM_DEFAULTS.LINE_ITEM_TYPE.VIDEO);
      const isAudio: Boolean = await this.isLineItemType(LINE_ITEM_DEFAULTS.LINE_ITEM_TYPE.AUDIO);
      this.resources.strategies_filtered = strategies;

      if (!this.line_item?.line_item_type_id) {
        this.setField("strategy_id", {
          show: true,
          required: true,
          disabled: false,
          value: undefined,
        });
        this.setField("bid_strategy_id", {
          show: true,
          required: true,
          disabled: false,
          value: undefined,
        });
        return;
      }

      this.resources.strategies_filtered = this.filterStrategies(strategies, isVideo, isAudio);
    },

    async updateBidStrategies() {
      // TODO refactorizar para una sola llamada

      //this.resources.bid_strategies = await this.dispatchBidStrategies();

      if (!this.line_item?.strategy_id || !this.line_item?.line_item_type_id) {
        this.setField("bid_strategy_id", {
          show: true,
          required: true,
          disabled: false,
          value: undefined,
        });
        return;
      }

      const isOptimizedCPM: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.CPM);

      const isOptimizedVCR: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.VCR);

      if (!isOptimizedCPM) {
        this.resources.bid_strategies = this.resources.bid_strategies.filter(
          (bs: any) => bs.value.toLowerCase() !== LINE_ITEM_DEFAULTS.BID_STRATEGY.FIX,
        );
      }

      // bid_strategy_id
      this.setField("bid_strategy_id", {
        show: true,
        required: true,
        disabled: isOptimizedVCR && !!this.line_item?.strategy_id,
        value: isOptimizedVCR
          ? this.findByValue(this.resources.bid_strategies, LINE_ITEM_DEFAULTS.BID_STRATEGY.AUTOMATED)?.id
          : undefined,
      });
    },

    async updateMaxCpmBid() {
      const isOptimizedCPC: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.CPC);

      const isAutomated: Boolean = await this.isBidStrategy(LINE_ITEM_DEFAULTS.BID_STRATEGY.AUTOMATED);

      // max_cpm_bid
      this.setField("max_cpm_bid", {
        show: isOptimizedCPC && isAutomated,
        required: isOptimizedCPC && isAutomated,
        disabled: !(isOptimizedCPC && isAutomated),
      });
    },

    findByValue(data: Array<any>, value: string) {
      return first(
        filter(data, function (o) {
          return String(o.value).toLowerCase() === value.toLowerCase();
        }),
      );
    },

    filterStrategies(data: Array<{ value: string }>, isVideo: boolean, isAudio: boolean) {
      const type = isVideo ? "video" : isAudio ? "audio" : "banner";
      const allowedOptions = STRATEGY_OPTIONS[type];
      return filter(data, o => allowedOptions.includes(parseToLower(o.value)));
    },

    async refreshData() {
      await this.fetchAndSetLineItem(this.line_item?.id);
    },

    async fetchTargetingExpressions() {
      return await this.$store.dispatch(
        "targeting/all",
        await ParamService.getParams({}, this.filters, this.options, true),
      );
    },

    /**
     * Aplica cambios en los datos obtenidos desde la campaña
     */
    async applyCampaignSelectedToLineItem(from_campaign: Boolean = false) {
      const campaign = this.getSelectedCampaign;
      const isByCampaign: Boolean = this.isCampaignOptimizationByCampaign;
      const isByLine: Boolean = this.isCampaignOptimizationByLine;
      const isAutomated: Boolean = await this.isBidStrategy(LINE_ITEM_DEFAULTS.BID_STRATEGY.AUTOMATED);
      const isFix: Boolean = await this.isBidStrategy(LINE_ITEM_DEFAULTS.BID_STRATEGY.FIX);

      const isPacingDaily: Boolean = await this.isLinePacing(LINE_ITEM_DEFAULTS.LINE_PACING.DAILY);
      const isPacingLifetime: Boolean = await this.isLinePacing(LINE_ITEM_DEFAULTS.LINE_PACING.LIFETIME);
      const isPacingAsap: Boolean = await this.isLinePacing(LINE_ITEM_DEFAULTS.LINE_PACING.ASAP);

      const isDisabledbehaviour = !(isPacingDaily || isPacingLifetime);
      const isShowbehaviour = isPacingDaily || isPacingLifetime;

      // budget
      this.setField("budget", {
        show: true,
        required: true,
        disabled: this.getSelectedCampaign && isByCampaign,
      });

      // line_pacing_id
      this.setField("line_pacing_id", {
        show: isByLine,
        required: true,
        disabled: !isByLine,
      });

      // daily_budget
      this.setField("daily_budget", {
        show: isByLine && isPacingDaily,
        required: isPacingDaily,
        disabled: !isPacingDaily,
        value: this.line_item?.daily_budget || campaign?.daily_budget,
      });

      this.setField("pacing_behavior_id", {
        show: isShowbehaviour,
        required: isShowbehaviour,
        disabled: isDisabledbehaviour,
      });

      this.setField("catchup_behavior_id", {
        show: isShowbehaviour,
        required: isShowbehaviour,
        disabled: isDisabledbehaviour,
      });

      this.setField("multiplier_pacing_behavior", {
        show: isShowbehaviour,
        required: isShowbehaviour,
        disabled: isDisabledbehaviour,
      });

      // daily_budget_suggested
      this.setField("daily_budget_suggested", {
        show: isPacingDaily,
        required: false,
        disabled: true,
      });

      if (from_campaign) {
        // budget
        this.setField("budget", {
          show: true,
          required: true,
          disabled: this.getSelectedCampaign && isByCampaign,
          value: isByCampaign ? campaign?.budget : this.line_item?.budget,
        });

        // start_date
        this.setField("start_date", {
          show: true,
          required: true,
          disabled: this.getSelectedCampaign && isByCampaign,
          //value: campaign?.start_date || undefined,
          value: (this.isEditRoute ? new Date(this.line_item.start_date) : new Date(campaign?.start_date)) ?? undefined,
        });

        // end_date
        this.setField("end_date", {
          show: true,
          required: true,
          disabled: false,
          //value: campaign?.end_date || undefined,
          value: (this.isEditRoute ? new Date(this.line_item.end_date) : new Date(campaign?.end_date)) ?? undefined,
        });

        // line_pacing_id
        this.setField("line_pacing_id", {
          show: true,
          required: true,
          disabled: !isByLine,
          value: isByLine ? this.line_item?.line_pacing_id ?? campaign?.campaign_pacing_id : undefined,
        });

        this.setField("pacing_behavior_id", {
          show: isShowbehaviour,
          required: isShowbehaviour,
          disabled: isDisabledbehaviour,
        });

        this.setField("catchup_behavior_id", {
          show: isShowbehaviour,
          required: isShowbehaviour,
          disabled: isDisabledbehaviour,
        });

        this.setField("multiplier_pacing_behavior", {
          show: isShowbehaviour,
          required: isShowbehaviour,
          disabled: isDisabledbehaviour,
        });

        this.setField("advertiser_id", {
          show: true,
          required: true,
          disabled: false,
          value: campaign?.advertiser_id,
        });

        // strategy_id
        this.setField("strategy_id", {
          show: true,
          required: true,
          disabled: false,
          value: this.line_ite?.strategy_id || campaign?.strategy_id,
        });

        // budget_type_id
        this.setField("budget_type_id", {
          show: true,
          required: true,
          disabled: true,
          value: campaign?.budget_type_id,
        });
      }

      //const isSpend: Boolean = await this.isBudgetType(BUDGET_TYPE_SPEND);
      const isImpression: Boolean = await this.isBudgetType(LINE_ITEM_DEFAULTS.BUDGET_TYPE.IMPRESSIONS);

      this.setLabel("daily_budget", `Daily ${isImpression ? "Impressions" : "Budget"}`);

      this.setLabel("daily_budget_suggested", `Daily ${isImpression ? "Impressions" : "Budget"} Suggested`);

      // fix_cpm
      this.setField("fix_cpm", {
        show: isFix,
        required: isFix,
        disabled: !isFix,
      });

      const isOptimizedCPC: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.CPC);

      const isOptimizedCPM: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.CPM);

      const isOptimizedVCR: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.VCR);

      // cpm_bid
      this.setField("cpm_bid", {
        show: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
        required: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
        disabled: !((isOptimizedVCR || isOptimizedCPM) && isAutomated),
      });

      // expected_ctr
      this.setField("expected_ctr", {
        show: isOptimizedCPC && isAutomated,
        required: isOptimizedCPC && isAutomated,
        disabled: false,
      });

      // max_cpm_bid
      this.setField("max_cpm_bid", {
        show: isOptimizedCPC && isAutomated,
        required: isOptimizedCPC && isAutomated,
        disabled: !(isOptimizedCPC && isAutomated),
      });

      // bid_strategy_id
      // this.setField("bid_strategy_id", {
      // 	show: true,
      // 	required: true,
      // 	disabled: false
      // });

      // bid_shading_id
      // this.setField("bid_shading_id", {
      // 	show: isOptimizedVCR || isOptimizedCPM && !isFix,
      // 	required: isOptimizedVCR || isOptimizedCPM,
      // 	disabled: !(isOptimizedVCR || isOptimizedCPM),
      // 	value: undefined
      // });

      // target_ecpc
      this.setField("target_ecpc", {
        show: isOptimizedCPC && isAutomated,
        required: isOptimizedCPC && isAutomated,
        disabled: !(isOptimizedCPC && isAutomated),
      });

      // target_vcr
      this.setField("target_vcr", {
        show: isOptimizedVCR && isAutomated,
        required: isOptimizedVCR && isAutomated,
        disabled: !(isOptimizedVCR && isAutomated),
      });

      // update strategies filtered
      await this.updateStrategies();

      //await this.updateBidStrategies();

      await this.updateBudgetDisplay();
    },

    async isOptimizationStrategy(key: any): Promise<Boolean> {
      return (
        find(this.resources.optimization_strategies, { value: key })?.id ===
        this.getSelectedCampaign?.optimization_strategy_id
      );
    },

    /**
     * Funcion generico para verificar por `key`
     * @param key
     * @param resourceKey
     * @param idKey
     * @returns
     */
    isMatchingResource(
      key: string,
      resourceKey: keyof typeof this.resources,
      idKey: keyof typeof this.line_item,
    ): boolean {
      const resourceList = this.resources[resourceKey] as { value: string; id: number }[];
      const result = find(resourceList, item => item.value.toLowerCase() === key.toLowerCase());
      return result?.id === this.line_item[idKey];
    },

    /**
     * Obtiene el `id` del elemento obtenido del resource buscado por `key`
     * @param key
     * @param resourceKey
     * @returns
     */
    getKeyMatchingResource(key: string, items: { value: string; id: number }[]): number | undefined {
      const result = find(items, item => item.value.toLowerCase() === key.toLowerCase());
      return result?.id ? Number(result?.id) : undefined;
    },

    async isBidStrategy(key: string): Promise<boolean> {
      return this.isMatchingResource(key, "bid_strategies", "bid_strategy_id");
    },

    async isBudgetType(key: string): Promise<boolean> {
      return this.isMatchingResource(key, "budget_types", "budget_type_id");
    },

    async isLineItemType(key: string): Promise<boolean> {
      return this.isMatchingResource(key, "line_item_types", "line_item_type_id");
    },

    async isStrategy(key: string): Promise<boolean> {
      return this.isMatchingResource(key, "strategies", "strategy_id");
    },

    async isLinePacing(key: string): Promise<boolean> {
      return this.isMatchingResource(key, "line_pacings", "line_pacing_id");
    },

    async getKeyBidStrategy(key: string): Promise<number | undefined> {
      return this.getKeyMatchingResource(key, this.resources.bid_strategies);
    },

    async getRamdomWeight(): Promise<number | undefined> {
      return this.getKeyMatchingResource(LINE_ITEM_DEFAULTS.COMMONS.RANDOM, this.resources.creative_weighting_methods);
    },

    async getPacingBehaviourEven(): Promise<number | undefined> {
      return this.getKeyMatchingResource(LINE_ITEM_DEFAULTS.COMMONS.EVEN, this.resources.pacing_behaviour);
    },

    async getCatchupBehaviourEven(): Promise<number | undefined> {
      return this.getKeyMatchingResource(LINE_ITEM_DEFAULTS.COMMONS.EVEN, this.resources.catchup_behaviour);
    },

    async setFilters(lineItemId: any) {
      this.filters = { line_item_id: lineItemId };
    },

    async setLineItem(lineItem: any) {
      this.line_item = lineItem;
      if (this.isAccountRM) {
        const retailer = this.getRetailers.find(
          (retailer: { id: number }) => retailer.id === this.line_item.retailer_id,
        );
        Object.assign(this.line_item, { retailer });
      }

      // Allow to add events or not after create the item
      this.allows_events = lineItem.allows_events;

      if (this.isEditRoute) {
        await this.updateCtrVcr();
      }
    },

    async setSelectedCampaign(campaign: any = { advertiser: { id: null, name: null }, external_id: null }) {
      const { id, name, advertiser } = campaign;

      this.resources.selected_campaign = campaign;
      this.line_item.campaign = campaign;
      this.line_item.campaign_id = campaign?.id ?? null;

      if (advertiser && advertiser?.id) {
        this.resources.advertisers = [{ id: advertiser.id, value: advertiser.name }];
      }

      if (id && name) {
        this.resources.campaigns = [...this.resources.campaigns, { id, value: name }];
      }
    },

    hasData(attr: any) {
      return !isUndefined(attr) && !isNull(attr);
    },

    isValidDates() {
      const startDate = this.moment(this.line_item.start_date);
      const endDate = this.moment(this.line_item.end_date);
      return startDate.isValid() && endDate.isValid();
    },

    getCalculateDuration() {
      if (!this.isValidDates()) return undefined;
      const startDate = this.moment(this.line_item.start_date);
      const endDate = this.moment(this.line_item.end_date);
      const days = this.calculateDuration(startDate, endDate);
      this.line_item.line_duration = days > -1 ? days : undefined;
      return this.line_item.line_duration;
    },
    calculateDuration(start: any, end: any) {
      if (!(start.isValid() && end.isValid())) {
        return -1;
      }
      //const diff = end.diff(start, "days");
      const duration = this.moment.duration(end.diff(start));
      return Math.ceil(duration.asDays());
    },

    async fetchResource(params: { resource: string; value?: any }) {
      try {
        this.setLoadingField(true);

        switch (params.resource) {
          case "campaign_id":
            try {
              if (params.value !== undefined) {
                this.verifyQuery(params.value);
                this.resources.campaigns = await this.dispatchCampaigns();
              }
            } catch (error) {}
            break;

          case "strategy_id":
            await this.updateStrategies();
            break;
        }
      } catch (error) {
      } finally {
        this.setLoadingField(false);
      }
    },

    /**
     * Se quita de la ruta: Si hay parametro en la ruta `campaign_id` y no corresponde al campaign_id de la linea
     * @param value
     */
    verifyQuery(value?: any) {
      if (this.hasCampaignID && value !== this.getCampaignID) {
        this.$router.replace({ query: null });
      }
    },

    async handleChange(params: { key: string | number; value: any }) {
      if (this.isLoading) return;
      this.setLoadingField(true);

      if (!isUndefined(params.value)) {
        this.line_item[params.key] = params.value;
      }

      switch (params.key) {
        case "line_duration":
          this.line_item[params.key] = this.getCalculateDuration();
          break;

        case "line_item_type_id":
          await this.updateStrategies();
          //await this.updateBidStrategies();
          break;

        case "strategy_id":
          //await this.updateBidStrategies();
          await this.updateMaxCpmBid();

          const isOptimizedCPC: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.CPC);

          const isOptimizedVCR: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.VCR);

          if (isOptimizedCPC || isOptimizedVCR) {
            const keyBidStrategy: Number = await this.getKeyBidStrategy(LINE_ITEM_DEFAULTS.BID_STRATEGY.AUTOMATED);
            this.line_item["bid_strategy_id"] = keyBidStrategy;
          }
          break;

        case "bid_strategy_id":
          const isFix: Boolean = await this.isBidStrategy(LINE_ITEM_DEFAULTS.BID_STRATEGY.FIX);

          // fix_cpm
          this.setField("fix_cpm", {
            show: isFix,
            required: isFix,
            disabled: !isFix,
          });

          await this.updateMaxCpmBid();

          break;

        case "campaign_id":
          await this.fetchCampaignByIdAndSetLineItem(Number(params.value));
          await this.updateStrategies();
          break;

        case "budget_type_id":
          break;

        case "line_pacing_id":
          this.line_item.line_duration = this.getCalculateDuration();
          break;

        case "daily_budget":
          this.line_item.line_duration = this.getCalculateDuration();
          break;

        case "target_ecpc":
          //await this.calcMaxCpmBid();
          await this.calcExpectedCTR();
          break;

        case "expected_ctr":
          //await this.calcMaxCpmBid();
          break;

        case "max_cpm_bid":
          await this.calcExpectedCTR();
          /*
          this.flag_clac_cpm_bid = true;
          await this.calcExpectedCTR()
          .then((response) => {
            this.flag_clac_cpm_bid = false;
          });*/
          break;

        case "retailer":
          this.retailer_isvalid = params.value?.id > 0;
          break;
      }

      await this.applyCampaignSelectedToLineItem(params.key === "campaign_id");

      this.setLoadingField(false);
    },

    async getStrategyByValue() {
      return this.resources.strategies.find(
        e => String(e.value).toUpperCase() == String(LINE_ITEM_DEFAULTS.OPTIMIZED.CPM).toUpperCase(),
      );
    },

    async getBidStrategyByValue() {
      return this.resources.bid_strategies.find(
        e => String(e.value).toUpperCase() == String(LINE_ITEM_DEFAULTS.BID_STRATEGY.FIX).toUpperCase(),
      );
    },

    async getLinePacingByValue(type: "asap" | "lifetime" | "daily" = "lifetime") {
      return this.resources.line_pacings.find(e => String(e.value).toLowerCase() === type);
    },

    async calcMaxCpmBid() {
      if (!this.line_item?.expected_ctr || !this.line_item?.target_ecpc) {
        this.line_item["max_cpm_bid"] = null;
        return;
      }

      const maxCpmBid = (this.line_item?.expected_ctr / 100) * (this.line_item?.target_ecpc * 1000);

      this.line_item["max_cpm_bid"] = maxCpmBid;
    },

    async calcExpectedCTR() {
      if (!this.line_item?.target_ecpc || !this.line_item?.max_cpm_bid) {
        //this.line_item["expected_ctr"] = null;
        return;
      }
      let valueCTR = this.line_item["expected_ctr"];
      const valExpectedCTR = (this.line_item?.max_cpm_bid / (1000 * this.line_item?.target_ecpc)) * 100;
      this.line_item["expected_ctr"] = valExpectedCTR.toFixed(2);

      /*
      if (!this.line_item?.expected_ctr || !this.line_item?.max_cpm_bid) {
        //this.line_item["expected_ctr"] = null;
        return;
      }
      let valueCTR = this.line_item["expected_ctr"];
      const valExpectedCTR = ((this.line_item?.max_cpm_bid / 1000) / valueCTR) * 100;
      this.line_item["expected_ctr"] = valExpectedCTR;*/
    },

    async loadDeliveryBidding() {
      const isAutomated: Boolean = await this.isBidStrategy(LINE_ITEM_DEFAULTS.BID_STRATEGY.AUTOMATED);

      const isOptimizedCPC: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.CPC);

      const isOptimizedCPM: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.CPM);

      const isOptimizedVCR: Boolean = await this.isStrategy(LINE_ITEM_DEFAULTS.OPTIMIZED.VCR);

      const isFix: Boolean = await this.isBidStrategy(LINE_ITEM_DEFAULTS.BID_STRATEGY.FIX);

      // cpm_bid
      this.setField("cpm_bid", {
        show: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
        required: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
        disabled: !((isOptimizedVCR || isOptimizedCPM) && isAutomated),
      });

      // fix_cpm
      this.setField("fix_cpm", {
        show: isFix,
        required: isFix,
        disabled: !isFix,
      });

      // expected_ctr
      this.setField("expected_ctr", {
        show: isOptimizedCPC && isAutomated,
        required: isOptimizedCPC && isAutomated,
        disabled: false,
      });

      // max_cpm_bid
      this.setField("max_cpm_bid", {
        show: isOptimizedCPC && isAutomated,
        required: isOptimizedCPC && isAutomated,
        disabled: !(isOptimizedCPC && isAutomated),
      });

      // target_ecpc
      this.setField("target_ecpc", {
        show: isOptimizedCPC && isAutomated,
        required: isOptimizedCPC && isAutomated,
        disabled: !(isOptimizedCPC && isAutomated),
      });

      // target_vcr
      this.setField("target_vcr", {
        show: isOptimizedVCR && isAutomated,
        required: isOptimizedVCR && isAutomated,
        disabled: !(isOptimizedVCR && isAutomated),
      });
    },

    getFrequencyCaps(): ResourceFrequecyCap | undefined {
      const lineItem: FormLineItem = this.line_item;
      const frequencyCap = head(lineItem.frequency_caps);
      if (!frequencyCap) return undefined;
      return this.resources.types_frecuency_caps.find(e => e.extra === frequencyCap.type_id);
    },

    async loadFrecuencyType() {
      if (!isEmpty(this.line_item?.frequency_caps)) {
        const frequencyCap: ResourceFrequecyCap | undefined = this.getFrequencyCaps();
        if (!frequencyCap) return;
        this.resources.type_frecuency_caps_selected = frequencyCap.id;
        this.updateTypesFrecuencyCaps();
      }
    },

    async updateGraphic(period: any) {
      if (isEmpty(period)) return;
      let hora = await this.getHourByPeriod(period);
      let current_Date = this.moment().format(DEFAULT_DATE_TIME_FORMAT);
      let date = this.moment().subtract(hora, "hours").format(DEFAULT_DATE_TIME_FORMAT);
      this.bid_win.start_date = date;
      this.bid_win.end_date = current_Date;
      this.fetchDataGraphicBidWin();
    },

    async getHourByPeriod(period_id: any) {
      return this.dataGraphic.period.find(p => p.id == period_id)?.hora;
    },

    async fetchDataGraphicBidWin() {
      try {
        this.dataGraphic.loadingGraph = true;
        this.dataGraphic.data = await this.dataGraphicBidWin(this.bid_win);
      } catch (error) {
        console.error(`[fetchDataGraphicBidWin]`, error);
      } finally {
        this.dataGraphic.loadingGraph = false;
      }
    },

    async redirectTo(lineItemId?: string | number, index?: string | number) {
      try {
        const route = this.getRouteToRedirect(lineItemId);
        this.setNotification({ title: "", message: "", type: "" });
        await this.$router.push(route).catch(error => console.error);
      } catch (error) {
        console.error("[Logic::redirectTo] Error:", error);
      }
    },

    getRouteToRedirect(lineItemId?: string | number, index?: string | number) {
      if (!lineItemId) {
        return { name: "LineItemList" };
      }

      const route: Record<string, any> = {
        name: "LineItemEdit",
        params: { id: String(lineItemId) },
      };

      if (index !== undefined) {
        route.query = { tabIndex: index };
      }

      return route;
    },

    isCurrentTabSelected(item: { key: number }, index: number): Boolean {
      return item.key === index;
    },

    async addFrecuencyCaps() {
      if (this.line_item.frequency_caps.length >= 3) return;
      this.line_item.frequency_caps.push(
        new FrequencyCap({
          impressions: undefined,
          every_time: undefined,
          unit_time_id: undefined,
          type: this.resources.type_frecuency_caps_selected,
        }),
      );
    },

    async updateTypesFrecuencyCaps() {
      this.line_item.frequency_caps.forEach(element => {
        element.type = this.resources.type_frecuency_caps_selected;
      });
    },

    async updateCtrVcr() {
      if (this.line_item?.expected_ctr) {
        this.line_item.expected_ctr = (this.line_item.expected_ctr * 100).toFixed(2);
      }

      if (this.line_item?.target_vcr) {
        this.line_item.target_vcr *= 100;
      }
    },

    /**
     * DISPATCH || GET
     */

    async dispatchCampaigns() {
      //if (!isEmpty(this.resources.campaigns)) return this.resources.campaigns;
      return this.$store.dispatch("campaign/list", {
        filters: {} as CampaingFilters,
        options: { sort: "name", order: "asc" } as CampaingOptions,
      });
    },

    /**
     * HANDLERS
     */

    async handleCancel() {
      try {
        this.$router.push({ name: "LineItemList" });
      } catch (error) {
        console.error("handleCancel", { error: error });
      }
    },

    async nextTabEnabled(value: number) {
      let tabNumber = null;
      this.getItems.some(t => {
        if (t.key > value && !t.disabled) {
          tabNumber = t.key;
          return true;
        }
      });
      return isNull(tabNumber) ? null : tabNumber;
    },

    async handleSubmit(params: { method: "create" | "update"; continue: Boolean; data: FormPayload }) {
      try {
        await this.setLoadingData(TypeLoading.loading);

        const lineItem = await this.processLineItem(params);
        const shouldContinue: Boolean = lineItem?.id && params.continue;

        window.scrollTo(0, 0);

        if (shouldContinue) {
          await this.fetchAndSetLineItem(lineItem.id);
          await this.setLoadingData();
          await this.redirectTo(lineItem.id, 1);
          this.updateCurrentTab(1);
          return;
        }

        this.updateCurrentTab(1);
      } catch (error) {
        console.error("[handleSubmit]", { error });
      } finally {
        await this.setLoadingData();
      }
    },

    async processLineItem(params: { method: "create" | "update"; continue: Boolean; data: FormPayload }) {
      return params.method === "create" ? this.createAndFetchLineItem(params) : this.updateAndFetchLineItem(params);
    },

    async createAndFetchLineItem(params: { method: "create"; continue: boolean; data: FormPayload }) {
      return await this.createLineItem(params);
    },

    async updateAndFetchLineItem(params: { method: "update"; continue: boolean; data: FormPayload }) {
      return await this.updateLineItem({ id: this.getID, continue: params.continue, data: params.data });
    },

    /**
     * From CardActions - targetings
     * @param action
     */

    async handleTargetingAction(action: any) {
      let result = null;
      switch (action.type) {
        case "save":
          result = await this.handleTargetingSubmit();
          if (result) {
            this.$router.push({ name: "LineItemList" });
          }
          break;

        case "save-continue":
          result = await this.handleTargetingSubmit();
          if (result) {
            this.updateSelectedTabIndex(2);
          }
          break;

        case "cancel":
          await this.handleCancel();
          break;
      }
    },

    async handleTargetingSubmit() {
      try {
        await this.setLoadingData(TypeLoading.loading);

        const isTarjeting = this.currentTab === 1;
        const isTimeInTarjeting = this.currentTabTargeting === 6;

        if (
          !this.line_item.guaranteed &&
          isTarjeting &&
          isTimeInTarjeting &&
          this.targeting["time"]["timezones"].value.length < 1
        ) {
          this.snack_notification.setSnackData({
            message: this.$t("lineItem.required.timezones"),
            type: SnactType.ERROR,
          });
          return;
        }

        const targetingData = {
          id: this.getTargetingExpressionsID,
          line_item_id: this.line_item.id,
          active: true,
          targeting_terms: prepareTargetingDataCreate(this.targeting) as Array<Term>,
        };

        let targetingExpressions: any = null;

        if (this.hasTargetingExpressionsID) {
          targetingExpressions = await this.updateTargeting(targetingData as TargetingDataUpdate);
        } else {
          targetingExpressions = await this.createTargeting(targetingData as TargetingDataCreate);
        }

        if (this.isEditRoute) {
          await this.fetchAndSetLineItem(this.getID);
        }

        /**
         * Preparar data y guardar timezones
         */
        if (!this.line_item.guaranteed) {
          const timezonesData = {
            line_item_id: null as number | null,
            timezone_ids: [] as number[],
          };

          if (isTarjeting && isTimeInTarjeting && this.targeting["time"]["timezones"].value.length > 0) {
            const timezoneIds: number[] = this.targeting["time"]["timezones"].value.map(timezone => timezone.id);
            timezonesData.line_item_id = this.line_item.id;
            timezonesData.timezone_ids = timezoneIds;
          }

          if (isTarjeting && isTimeInTarjeting) {
            await this.updateTimezones(timezonesData);
          }
        }

        return targetingExpressions;
      } catch (error) {
        console.error("handleTargetingSubmit", { error: error });
        return null;
      } finally {
        await this.setLoadingData();
      }
    },

    async handleClear(key: any) {
      switch (key) {
        case "clear-relations":
          this.line_item.start_date = null;
          this.line_item.end_date = null;
          this.line_item.line_duration = null;
          this.resources.selected_campaign = { advertiser: { id: null, name: null }, external_id: null };

          // advertiser_id
          this.setField("advertiser_id", {
            show: true,
            required: true,
            disabled: true,
            value: null,
          });

          // budget_type_id
          this.setField("budget_type_id", {
            show: true,
            required: true,
            disabled: false,
            value: null,
          });

          // budget
          this.setField("budget", {
            show: true,
            required: true,
            disabled: false,
            value: null,
          });

          // line_pacing_id
          this.setField("line_pacing_id", {
            show: true,
            required: false,
            disabled: true,
            value: null,
          });

          // strategy_id
          this.setField("strategy_id", {
            show: true,
            required: false,
            disabled: true,
            value: null,
          });

          // bid_strategy_id
          this.setField("bid_strategy_id", {
            show: true,
            required: true,
            disabled: true,
            value: null,
          });

          // daily_budget
          // this.setField("daily_budget", {
          // 	show: false,
          // 	required: false,
          // 	disabled: true,
          // 	value: null
          // });

          // cpm_bid
          // this.setField("cpm_bid", {
          // 	show: false,
          // 	required: false,
          // 	disabled: true,
          // 	value: null
          // });

          await this.updateBudgetDisplay();

          break;

        default:
          break;
      }
    },

    async handleActivate() {
      try {
        await this.setLoadingData(TypeLoading.loading);
        await this.activate(this.line_item.id);
      } catch (error) {
        console.error("handleActivate", { error: error });
      } finally {
        await this.setLoadingData();
      }
    },

    async updateSelectedTabIndex(index: number = 0) {
      if (this.getItems.find(t => t.key == index).disabled) {
        let next = await this.nextTabEnabled(index);
        if (isNull(next)) {
          await this.redirectTo();
        } else {
          this.currentTab = next;
        }
      } else {
        this.currentTab = index;
      }
    },

    updateSelectedTabTargetingIndex(index: number) {
      this.currentTabTargeting = index;
    },

    updateCurrentTab(index: number) {
      this.currentTab = index;
    },

    /**
     *  ACTIONS STORE
     */
    async createLineItem(params: { method: string; continue: Boolean; data: LineItemDataCreate }) {
      return this.$store.dispatch("line_item/create", params);
    },

    async updateLineItem(params: { method: string; continue: Boolean; data: LineItemDataUpdate }) {
      return this.$store.dispatch("line_item/update", params);
    },

    async activate(id: number) {
      return this.$store.dispatch("line_item/activate", id);
    },

    async createTargeting(targeting: TargetingDataCreate) {
      return this.$store.dispatch("targeting/create", targeting);
    },

    async updateTargeting(targeting: TargetingDataUpdate) {
      return this.$store.dispatch("targeting/update", targeting);
    },

    async updateTimezones(props: { line_item_id: number; timezone_ids: number[] }) {
      return this.$store.dispatch("timezone/update", props);
    },

    setNotification(notification: Notification) {
      return this.$store.dispatch("proccess/setNotification", notification, {
        root: true,
      });
    },

    setLabel(key: string, value: any) {
      this.fieldLabel[key] = value;
    },

    /**
     * key
     * element: required?, show?, disabled?, value
     */
    setField(key: string, element: { required: any; show: any; disabled: any; value: any }) {
      this.resources.fields[key] = {
        required: element.required,
        show: element.show,
        disabled: element.disabled,
      };
      if (!isUndefined(element.value)) {
        this.line_item[key] = element.value;
      }
    },

    /**
     * Add item
     */
    addItem(params: {
      tab: string | number;
      key: string | number;
      value: any;
      value_name: string;
      verb_usage?: number;
      segment_name?: string;
      verb_usage_name?: string;
      is_category?: boolean;
    }) {
      const term: any = {
        value: String(params.value),
        TermSelected: [{ id: params.value, value: params.value_name }],
        targeting_key_id: getTargetingIDByValue()[params.tab][params.key],
        targeting_predicate_id: isUndefined(params.verb_usage)
          ? this.isIncluded
          : this.getPredicatedVerbUsage(params.verb_usage),
        verb_usage: isUndefined(params.verb_usage) ? undefined : params.verb_usage,
        segment_name: isUndefined(params.segment_name) ? undefined : params.segment_name,
        verb_usage_name: isUndefined(params.verb_usage_name) ? undefined : params.verb_usage_name,
        is_category: isUndefined(params.is_category) ? undefined : params.is_category,
      };

      const existTerm: any[] = this.targeting[params.tab][params.key].targeting_terms.filter(
        (tt: { value: any }) => tt.value == term.value,
      );

      if (existTerm.length < 1) {
        this.targeting[params.tab][params.key].targeting_terms.push(term);
      }
    },

    getPredicatedVerbUsage(verb) {
      switch (verb) {
        case 604:
          return this.isIncluded;
        case 605:
          return this.isIncluded;
        case 606:
          return this.isExcluded;
        case 607:
          return this.isRequired;
        default:
          return this.isIncluded;
      }
    },

    /**
     * Add item unique
     */
    addItemUnique(params: {
      tab: string | number;
      key: string | number;
      value: any;
      value_name: string;
      reset: Boolean | undefined;
      extra?: string;
    }) {
      if (!isEmpty(trim(params.value))) {
        if (!isEmpty(this.targeting[params.tab][params.key].targeting_terms)) {
          if (!this.targeting[params.tab][params.key].targeting_terms[0].value.includes(trim(params.value))) {
            this.targeting[params.tab][params.key].targeting_terms[0].value.push(trim(params.value));

            if (this.targeting[params.tab][params.key].targeting_terms[0].hasOwnProperty("TermSelected")) {
              this.targeting[params.tab][params.key].targeting_terms[0]["TermSelected"].push({
                id: params.value,
                value: params.value_name,
                extra: params.extra,
              });
            } else {
              this.targeting[params.tab][params.key].targeting_terms[0]["TermSelected"] = [
                { id: params.value, value: params.value_name, extra: params.extra },
              ];
            }
          }
        } else {
          this.targeting[params.tab][params.key].targeting_terms.push({
            value: [trim(String(params.value))],
            targeting_key_id: getTargetingIDByValue()[params.tab][params.key],
            targeting_predicate_id: this.isIncluded,
            TermSelected: [{ id: params.value, value: params.value_name, extra: params.extra }],
          });
        }
      }

      if (!isUndefined(params.reset) && Boolean(params.reset)) {
        this.targeting[params.tab][params.key].value = "";
      }
    },

    updateItemUnique(params: { tab: any; key: any; items: Array<string> }) {
      let terms: Array<any> = [];

      params.items.forEach(item => {
        if (isEmpty(trim(item))) return;
        if (isEmpty(terms)) {
          terms.push({
            value: [trim(String(item))],
            targeting_key_id: getTargetingIDByValue()[params.tab][params.key],
            targeting_predicate_id: this.isIncluded,
          });
        } else {
          if (!terms[0].value.includes(trim(item))) {
            terms[0].value.push(trim(String(item)));
          }
        }
      });

      this.targeting[params.tab][params.key].targeting_terms = terms;
    },

    /**
     * Remove item unique
     */
    removeItemUnique(params: { tab: any; key: any; value: any }) {
      /**
       * Targeting Terms by {{key}}
       */
      if (!isEmpty(this.targeting[params.tab][params.key].targeting_terms)) {
        const targetingTerm = this.targeting[params.tab][params.key].targeting_terms[0];

        if (isEmpty(targetingTerm.value)) return;

        if (this.targeting[params.tab][params.key].targeting_terms[0].hasOwnProperty("TermSelected")) {
          const index_term_selected = targetingTerm.TermSelected.findIndex(
            (v: any) => String(v.id) == String(params.value),
          );
          if (index_term_selected > -1) {
            this.targeting[params.tab][params.key].targeting_terms[0].TermSelected.splice(index_term_selected, 1);
          }
        }

        if (this.targeting[params.tab][params.key].targeting_terms[0].hasOwnProperty("value_name")) {
          const index_name = targetingTerm.value.findIndex((v: string) => v == String(params.value));
          if (index_name > -1) {
            this.targeting[params.tab][params.key].targeting_terms[0].value_name.splice(index_name, 1);
          }
        }

        const index = targetingTerm.value.findIndex((v: string) => v == String(params.value));

        if (index > -1) {
          this.targeting[params.tab][params.key].targeting_terms[0].value.splice(index, 1);

          if (isEmpty(this.targeting[params.tab][params.key].targeting_terms[0].value)) {
            this.targeting[params.tab][params.key].targeting_terms = [];
          }
        }
      }

      /**
       * targeting.{tab}.{key}.value
       */

      if (!this.targeting[params.tab][params.key].value) return;

      if (isString(this.targeting[params.tab][params.key].value)) {
        const separator = ",";

        let splitted = String(this.targeting[params.tab][params.key].value).split(separator);

        const index = splitted.indexOf(String(params.value));

        if (index > -1) {
          splitted.splice(index, 1);

          this.targeting[params.tab][params.key].value = splitted.join(separator);
        }
      }

      if (isArray(this.targeting[params.tab][params.key].value)) {
        const _index = this.targeting[params.tab][params.key].value.findIndex((v: string) => v == String(params.value));

        if (_index > -1) {
          this.targeting[params.tab][params.key].value.splice(_index, 1);
        }
      }
    },

    /**
     * Remove item
     */
    removeItem(params: { tab: any; key: any; value: any }) {
      /**
       * Targeting Terms by {{key}}
       */
      const index = this.targeting[params.tab][params.key].targeting_terms
        .filter((t: any) => t.targeting_key_id == getTargetingIDByValue()[params.tab][params.key])
        .findIndex((t: { value: string }) => t.value == String(params.value));

      if (index > -1) {
        this.targeting[params.tab][params.key].targeting_terms.splice(index, 1);
      }

      /**
       * targeting.{tab}.{key}.value
       */
      const _index = this.targeting[params.tab][params.key].value.findIndex((v: string) => v == String(params.value));

      if (_index > -1) {
        this.targeting[params.tab][params.key].value.splice(_index, 1);
      }
    },

    /**
     * Clear All items
     */
    clearHandler(params: { key: any; tab: any }) {
      this.targeting[params.tab][params.key].value = [];
      this.targeting[params.tab][params.key].targeting_terms = [];
    },

    /**
     * Update variables auxiliares
     */
    updateDataVariables(params: any) {
      this.data_variables[params.tab][params.key] = params.data;
    },

    addCommaHandler(params: { tab: any; key: any }) {
      this.targeting[params.tab][params.key].value = this.targeting[params.tab][params.key].value.concat(",");
    },

    /**
     * Update Selected Number Range
     */
    updateSelectedChecked(params: { tab: any; key: any; value: any; targeting_predicate_id: Number }) {
      const finded = find(this.targeting[params.tab][params.key].targeting_terms, {
        value: String(params.value),
      });

      if (!isEmpty(finded)) {
        this.targeting[params.tab][params.key].targeting_terms = filter(
          this.targeting[params.tab][params.key].targeting_terms,
          function (s) {
            return s.value != String(params.value);
          },
        );
      } else {
        this.targeting[params.tab][params.key].targeting_terms.push({
          value: String(params.value),
          targeting_key_id: this.targeting[params.tab][params.key].targeting_key_id,
          targeting_predicate_id: params.targeting_predicate_id,
        });
      }
    },

    updateSelectedPredicate(params: { tab: any; key: any; value: any }) {
      this.targeting[params.tab][params.key].targeting_terms.forEach((t: { targeting_predicate_id: any }) => {
        t.targeting_predicate_id = params.value;
      });
    },
    updateSelectTimezones(params: { tab: any; key: any; value: any; targeting_predicate_id: Number }) {
      this.targeting[params.tab][params.key].value = params.value;
    },
    updateCurrencyTab(index: number) {
      this.currentTabTargeting = 0;
    },

    initTargetingTime(params) {
      this.targeting[params.tab][params.key].targeting_terms = params.targeting_terms;
    },

    /**
     * From CardActions - Modifiers/Models
     * @param action
     */

    async handleSubmitModifier(params: any) {
      try {
        this.setLoadingData(TypeLoading.loading);
        const result = await this.dispatchCreateModifier(
          params.entity,
          params?.isBidModifier,
          params?.isDeliveryModifier,
        );

        if (params?.isBidModifier) {
          this.line_item.modifier_id = result.id;
        }
        if (params?.isDeliveryModifier) {
          this.line_item.delivery_modifier_id = result.id;
        }

        this.updateCountBidModifier = false;
        if (isUndefined(result) || isNull(result) || isEmpty(result)) {
          this.setNotification({
            title: this.$t("title-failed"),
            message: this.$t("failed"),
            btn_text: this.$t("try-again"),
            type: MessageTypes.ERROR,
            show: true,
          });
        } else {
          this.setNotification({
            title: this.$t("title-success"),
            message: this.$t("success"),
            btn_text: this.$t("continue"),
            type: MessageTypes.SUCCESS,
            show: true,
          });
          await params.executeDispatchFunction();
          await params.executeClearFunction();
        }
      } catch (error) {
        console.error("handleSubmitModifier", { error: error });
      } finally {
        await this.setLoadingData();
      }
    },

    async handleCreateAssociation(params: any) {
      try {
        this.setLoadingData(TypeLoading.loading);
        const result = await this.dispatchCreateAssociation(params.module, params.entity);
        if (!isUndefined(result) && !isNull(result) && !isEmpty(result)) {
          if (params.continue) {
            this.setNotification({
              title: this.$t("title-success"),
              message: this.$t("success"),
              btn_text: this.$t("continue"),
              type: MessageTypes.SUCCESS,
              show: true,
            });
            //this.$router.push({ name: "CreativesCreate" });
            this.updateSelectedTabIndex(3);
          } else {
            this.setNotification({
              title: this.$t("title-success"),
              message: this.$t("success"),
              btn_text: this.$t("continue"),
              type: MessageTypes.SUCCESS,
              show: true,
            });
            this.$router.push({ name: "LineItemList" });
          }
        }
      } catch (error) {
        console.error("handleCreateAssociation", { error: error });
      } finally {
        await this.setLoadingData();
      }
    },

    updateSelectedTabModifiersIndex(index: number) {
      this.currentTabModifiers = index;
    },

    async handleSaveContinueCreative() {
      try {
        await this.setLoadingData(TypeLoading.loading);
        this.setNotification({
          title: this.$t("title-success"),
          message: this.$t("success"),
          btn_text: this.$t("continue"),
          type: MessageTypes.SUCCESS,
          show: true,
        });
        this.updateSelectedTabIndex(4);
      } catch (error) {
        console.error("handleSaveContinueCreative", { error: error });
      } finally {
        await this.setLoadingData();
      }
    },

    async dispatchCreateAssociation(module: string, entity: any) {
      let result: any = undefined;
      switch (module) {
        case "BID_MODIFIER":
          result = await this.dispatchCreateAssociationWithBidModifier(entity);
          break;
        case "DELIVERY_MODIFIERS":
          result = await this.dispatchCreateAssociationWithBidModifier(entity);
          break;
      }
      return result;
    },

    async dispatchCreateAssociationWithBidModifier(entity: any) {
      return await this.$store.dispatch("line_item/createAssociationWithModifier", { entity: entity });
    },

    async dispatchCreateModifier(entity: any, isBidModifier: Boolean = false, isDeliveryModifier: Boolean = false) {
      return this.$store.dispatch("modifier/createModifier", {
        modifier: entity,
        isBidModifier: isBidModifier,
        isDeliveryModifier: isDeliveryModifier,
      });
    },

    getCampaignPacing() {
      const { campaign_pacing_id, campaign_pacing } = this.getSelectedCampaign;
      return campaign_pacing_id ?? campaign_pacing?.id;
    },

    getPacingType() {
      return this.isGuaranteed ? "asap" : "lifetime";
    },

    async setLinePacing() {
      let pacingId: string | undefined;

      if (this.isCampaignOptimizationByLine) {
        const linePacing = await this.getLinePacingByValue(this.getPacingType());
        pacingId = linePacing?.id;
      }

      if (this.isCampaignOptimizationByCampaign) {
        pacingId = this.getCampaignPacing();
      }

      this.line_item.line_pacing_id = pacingId;
    },

    async setStrategy() {
      const strategy = await this.getStrategyByValue();
      this.line_item.strategy_id = strategy?.id;
    },

    async setBidStrategy() {
      const bidStrategy = await this.getBidStrategyByValue();
      this.line_item.bid_strategy_id = bidStrategy?.id;
    },

    updateFieldsForCampaignPMP: debounce(async function () {
      if (this.isCampaignSelectedPMP) {
        this.setStrategy();
        this.setBidStrategy();
      }
      this.setLinePacing();
    }, 300),

    async forceRenderComponent() {
      this.$forceUpdate();
      this.renderComponent = false;
      await sleep(100);
      this.renderComponent = true;
    },
  },
  watch: {
    async "line_item.max_cpm_bid"(val, old) {
      if (isUndefined(old)) {
        this.line_item.expected_ctr = "";
        return;
      }
      await this.calcExpectedCTR();
      /*
      if(!this.flag_clac_cpm_bid){
        await this.calcMaxCpmBid();
      }*/
    },
    async "line_item.target_ecpc"(val, old) {
      if (isUndefined(old)) {
        this.line_item.expected_ctr = "";
        return;
      }
      await this.calcExpectedCTR();
      /*
      if(!this.flag_clac_cpm_bid){
        await this.calcMaxCpmBid();
      }*/
    },
    async "line_item.guaranteed"(val: boolean) {
      this.updateFieldsForCampaignPMP();
      this.line_item.frequency_caps = [];
    },
    async "resources.type_frecuency_caps_selected"(val, old) {
      await this.updateTypesFrecuencyCaps();
    },
    async getFrecuencyCapType(val, old) {
      await this.loadFrecuencyType();
    },
    async currentTab() {
      const isTarjeting: boolean = this.currentTab === 1;
      this.currentTabTargeting = 8;
      if (isTarjeting) {
        this.loadTargeting = true;
        this.targeting = await this.fetchTargetingExpressions();
        this.loadTargeting = false;
      }
    },
    async $route(to, from) {
      await this.forceRenderComponent();
    },
  },
});
