/**
 * *************************
 * ITEMS PARA EL TAB HORIZAONTAL DE LINE ITEM
 * *************************
 */

export class LineTabItem {
  key: number;
  tab: string;
  disabled: boolean = false;

  constructor(payload?: Partial<LineTabItem>) {
    this.key = payload?.key ?? 0;
    this.tab = payload?.tab ?? "";
    this.disabled = payload?.disabled ?? false;
  }

  setDisabled(disabled: boolean = false) {
    this.disabled = disabled;
  }
}

export const LINE_TAB_ITEMS: LineTabItem[] = [
  new LineTabItem({ key: 0, tab: "lineItem.tabs.overview", disabled: false }),
  new LineTabItem({ key: 1, tab: "lineItem.tabs.targeting", disabled: false }),
  new LineTabItem({ key: 2, tab: "lineItem.tabs.modifier", disabled: false }),
  new LineTabItem({ key: 3, tab: "lineItem.tabs.creative", disabled: false }),
  new LineTabItem({ key: 4, tab: "lineItem.tabs.event", disabled: false }),
];

/**
 * *************************
 * ITEMS PARA EL TAB VERTICAL DE TARGETING EN LINE ITEM
 * *************************
 */

export class TargetingSidebarNav {
  key: number;
  tab: string;
  disabled: boolean = false;
  show: boolean = true;
  count: number = 0;
  src: string = "";

  constructor(payload?: Partial<TargetingSidebarNav>) {
    this.key = payload?.key ?? 0;
    this.tab = payload?.tab ?? "";
    this.disabled = payload?.disabled ?? false;
    this.show = payload?.show ?? true;
    this.count = payload?.count ?? 0;
    this.src = payload?.src ?? "";
  }

  setDisabled(disabled: boolean = false) {
    this.disabled = disabled;
  }

  setCount(count: number = 0) {
    this.count = count;
  }

  setSrc(src: string = "") {
    this.src = src;
  }
}
