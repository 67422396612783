import { Component, Vue } from "vue-property-decorator";
import DispatchMixin from "@/mixins/Dispatch";
import { dirResourcesMapping } from "@/mixins/LineItem/ResourceData";
import { FormLineItem, FormPayload } from "@/models/LineItem/Form";
import { EditionRequiredResource, InitialRequiredResource, RequiredResource } from "@/interfaces/LineItem/types";

@Component({
  mixins: [DispatchMixin],
})
export default class LineItemDispatchMixin extends Vue {
  /**
   * Método genérico para despachar acciones en el store.
   */
  private readonly dispatchStore!: <T>(moduleFunc: string, data?: T) => Promise<any>;

  /**
   * Crea un nuevo LineItem.
   * @param params - Contiene el método HTTP, si se debe continuar y los datos del formulario.
   */
  async createLineItem(params: { method: string; continue: boolean; data: FormPayload }) {
    return this.dispatchStore(dirResourcesMapping.lineItemCreate, params);
  }

  /**
   * Obtiene un LineItem por su ID.
   * @param id - Identificador del LineItem.
   * @returns Instancia de FormLineItem con los datos obtenidos.
   */
  async fetchLineItemById(id: number) {
    const response = await this.dispatchStore(dirResourcesMapping.lineItemById, id);
    return new FormLineItem(response);
  }

  /**
   * Obtiene una Campaña por su ID.
   * @param id - Identificador del Campaña.
   * @returns Instancia de Campaña con los datos obtenidos.
   */
  async fetchCampaignById(id: number) {
    const response = await this.dispatchStore(dirResourcesMapping.campaignById, id);
    return response;
  }

  /**
   * Método genérico para cargar múltiples recursos desde el store.
   * @param mappings - Objeto que mapea los nombres de los recursos a sus rutas en el store.
   * @returns Un objeto con los recursos cargados o vacíos en caso de error.
   */
  private async loadResources<T>(mappings: Record<string, string>): Promise<T> {
    const results = await Promise.allSettled(Object.values(mappings).map(resource => this.dispatchStore(resource)));

    return Object.keys(mappings).reduce((acc, key, index) => {
      const result = results[index] as PromiseFulfilledResult<any> | PromiseRejectedResult;
      acc[key as keyof T] = result.status === "fulfilled" ? result.value ?? [] : [];
      if (result.status === "rejected") {
        console.error(`Error loading resource ${key}:`, result.reason);
      }
      return acc;
    }, {} as T);
  }

  /**
   * Carga los recursos iniciales requeridos al cargar el componente.
   * @returns Objeto con los recursos iniciales.
   */
  async loadInitialRequiredResources(): Promise<InitialRequiredResource> {
    return this.loadResources<InitialRequiredResource>({
      budget_types: dirResourcesMapping.budget_types,
      bid_strategies: dirResourcesMapping.bid_strategies,
      strategies: dirResourcesMapping.strategies,
      line_pacings: dirResourcesMapping.line_pacings,
      pacing_behaviour: dirResourcesMapping.pacing_behaviour,
      catchup_behaviour: dirResourcesMapping.catchup_behaviour,
    });
  }

  /**
   * Carga los recursos requeridos para la edición de un LineItem.
   * @returns Objeto con los recursos requeridos en la edición.
   */
  async loadEditionRequiredResources(): Promise<EditionRequiredResource> {
    return this.loadResources<EditionRequiredResource>({
      optimization_strategies: dirResourcesMapping.optimization_strategies,
      line_pacings: dirResourcesMapping.line_pacings,
      creative_weighting_methods: dirResourcesMapping.creative_weighting_methods,
      unit_times: dirResourcesMapping.unit_times,
      types_frecuency_caps: dirResourcesMapping.types_frecuency_caps,
      line_item_types: dirResourcesMapping.line_item_types,
    });
  }

  /**
   * Cargar recursos globales requeridos para `creacion` y `edicion`
   * @returns Objeto con los recursos requeridos.
   */
  async loadRequiredResources(): Promise<RequiredResource> {
    return this.loadResources<RequiredResource>({
      budget_types: dirResourcesMapping.budget_types,
      bid_strategies: dirResourcesMapping.bid_strategies,
      strategies: dirResourcesMapping.strategies,
      line_pacings: dirResourcesMapping.line_pacings,
      pacing_behaviour: dirResourcesMapping.pacing_behaviour,
      catchup_behaviour: dirResourcesMapping.catchup_behaviour,
      optimization_strategies: dirResourcesMapping.optimization_strategies,
      creative_weighting_methods: dirResourcesMapping.creative_weighting_methods,
      unit_times: dirResourcesMapping.unit_times,
      types_frecuency_caps: dirResourcesMapping.types_frecuency_caps,
      line_item_types: dirResourcesMapping.line_item_types,
      targeting_predicates: dirResourcesMapping.getTargetingPredicates,
    });
  }
}
