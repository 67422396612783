
import Vue from "vue";

export default Vue.extend({
  name: "CampaignSummary",

  props: {
    items: {
      type: Array,
      default: function () {
        return [] as any[];
      },
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  data: () => ({
    title: "CampaignSummary",
    campaign_summary: [],
  }),

  created() {},

  mounted() {},

  computed: {},
  methods: {
    toggleOpen() {
      this.$emit("open", Boolean(!this.open));
    },
  },
  watch: {},
});
