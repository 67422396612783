import { DirResourcesMapping } from "@/interfaces/LineItem/types";

export const dirResourcesMapping: DirResourcesMapping = {
  // Line item
  lineItemCreate: "line_item/create",
  lineItemUpdate: "line_item/update",
  lineItemById: "line_item/show",
  lineItemActivate: "line_item/activate",
  lineItemCreateAssociationWithModifier: "line_item/createAssociationWithModifier",
  lineItemDataGraphicBidWin: "line_item/dataGraphicBidWin",
  lineItemPeriodGraphicBidWin: "line_item/periodGraphicBidWin",
  lineItemSetLine: "line_item/setLine",

  // Targeting
  targetingCreate: "targeting/create",
  targetingUpdate: "targeting/update",
  targetingAll: "targeting/all",
  getTargetingKeys: "targeting/getTargetingKeys",
  getTargetingPredicates: "targeting/getTargetingPredicates",

  // Modifier
  modifierCreate: "targeting/createModifier",

  // Campaign
  campaignById: "campaign/getById",
  campaignList: "campaign/list",

  // Timezone
  timezoneById: "timezone/getTimezonesKeys",
  timezoneUpdate: "timezone/update",

  // Notification
  setNotification: "proccess/setNotification",

  // List
  budget_types: "custom_list/getBudgetTypes",
  optimization_strategies: "custom_list/getOptimizationStrategies",
  strategies: "custom_list/getStrategies",
  bid_strategies: "custom_list/getBidStrategies",
  unit_times: "custom_list/getUnitTimes",
  creative_weighting_methods: "custom_list/getCreativeWeightingMethods",
  line_pacings: "custom_list/getLinesPacing",
  pacing_behaviour: "custom_list/getPacingBehaviour",
  catchup_behaviour: "custom_list/getCatchupBehaviour",
  bidding_shadings: "custom_list/getBiddingShadings",
  types_frecuency_caps: "custom_list/getTypesFrecuencyCaps",

  // Paginated
  line_item_types: "custom_list/getLineItemTypes",
};

export const LINE_ITEM_DEFAULTS = {
  OPTIMIZATION: {
    BY_CAMPAIGN: "by campaign",
    BY_LINE: "by line",
  },
  BID_STRATEGY: {
    FIX: "fix",
    AUTOMATED: "automated",
  },
  BUDGET_TYPE: {
    IMPRESSIONS: "impressions",
    SPEND: "spend",
  },
  OPTIMIZED: {
    CPM: "optimized cpm",
    CPC: "optimized cpc",
    VCR: "optimized vcr",
  },
  LINE_ITEM_TYPE: {
    VIDEO: "video",
    AUDIO: "audio",
  },
  LINE_PACING: {
    DAILY: "daily",
    ASAP: "asap",
    LIFETIME: "lifetime",
  },
  PREDICATES: {
    EXCLUDED: "None",
    INCLUDED: "All",
    REQUIRED: "Require",
  },
  COMMONS: {
    RANDOM: "RANDOM",
    EVEN: "Even",
  },
};

export const STRATEGY_OPTIONS: Record<string, string[]> = {
  banner: [LINE_ITEM_DEFAULTS.OPTIMIZED.CPM, LINE_ITEM_DEFAULTS.OPTIMIZED.CPC],
  audio: [LINE_ITEM_DEFAULTS.OPTIMIZED.CPM],
  video: [LINE_ITEM_DEFAULTS.OPTIMIZED.VCR, LINE_ITEM_DEFAULTS.OPTIMIZED.CPM],
};
