
import { uniqBy } from "lodash";
import Vue from "vue";
import CardSwitch from "../../../../../../components/Content/CardSwitch.vue";

export default Vue.extend({
	name: "TermListUnique",
	model: {
		prop: "appLists",
		event: "input",
	},
	props: {
		targeting_terms: {
			type: Array,
			default: function () {
				return [] as any[];
			},
		},
		appLists: {
			type: Array,
			default: function () {
				return [] as any[];
			},
		},
		use_display_name: {
			type: Boolean,
			default: true,
		},
		predicates: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly:{
			type:Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		reference: {
			type: String,
			default: ""
		},
	},
	components: { CardSwitch },
	data: () => ({
		dataList: [] as Array<any>,
	}),
	created() {},
	mounted() {},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.appLists;
			},
		},
	},
	methods: {
		getDisplayNameByValue(targeting_term: any, index: number, value: any) {
			const app = this.modelData.find((item: any) => item.id == value);

			if (app) return app.value;

			if(targeting_term.hasOwnProperty("value_name")){
				const value_name = targeting_term?.value_name[index];
				if(value_name){
					return value_name;
				}else{
					if(targeting_term.hasOwnProperty("TermSelected")){
						const item = targeting_term?.TermSelected.find((val) => val.id == value);
						return item.value;
					}else{
						return "";
					}
				}
			}else{
				if(targeting_term.hasOwnProperty("TermSelected")){
					const item = targeting_term?.TermSelected.find((val) => val.id == value);
					return item.value;
				}else{
					return "";
				}
			}
		},

		removeHandler(value: any) {
			this.$emit("remove-item-unique", value);
		},

		isIncluded(targeting_predicate_id: Number) {
			return this.predicates.INCLUDED === targeting_predicate_id;
		},
	},
	watch: {
		appLists(val: Array<any>) {
			let newList: Array<any> = this.modelData;
			newList = newList.concat(val);
			newList = uniqBy(newList, "id");
			this.dataList = newList;
		},
	},
});
