import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import AmchartGlobal from "@/utils/amcharts/global";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { getCellSize, newYAxisRenderer, optionsYAxisRenderer } from "./utils";
import { BidWin } from "@/interfaces/graphic";

class AmchartCombineBulletGraph {

    root: any = null;
	exporting: any = null;

	async dispose(id: string) {
		am5.array.each(am5.registry.rootElements, function (root: am5.Root) {
			if (root?.dom?.id == id) {
				root.dispose();
			}
		});
	}

    async setup(params: {
		id: string;
		infoData: any;
		source: Array<any>;
        valXAxis: string;
        valCol0: string;
        valCol1: string;
        tooltipText?: string; // Añadir tooltipText como opcional
	}){
        await this.dispose(params.id);

        this.root = am5.Root.new(params.id);

        this.root.setThemes([am5themes_Animated.new(this.root)]);

        this.root.dateFormatter.setAll({
            dateFormat: "HH:mm",
            dateFields: ["valueX"],
        });

		var chart = this.root.container.children.push(
			am5xy.XYChart.new(this.root, {
				panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
				pinchZoomX: true,
                layout: this.root.verticalLayout
			})
		);

        let cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(this.root, {
                behavior: "zoomX",
            })
        );
        cursor.lineY.set("visible", false);
        
        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(this.root, {
                baseInterval: { timeUnit: "hour", count: 1 },
                renderer: am5xy.AxisRendererX.new(this.root, {}),
                tooltip: am5.Tooltip.new(this.root, {}),
                tooltipDateFormat: "HH:mm",
            })
        );
        
        let yAxis0 = chart.yAxes.push(
            am5xy.ValueAxis.new(this.root, {
                renderer: am5xy.AxisRendererY.new(this.root, {}),
            })
        );
        
        let yRenderer1 = am5xy.AxisRendererY.new(this.root, {
            opposite: true,
        });
        
        yRenderer1.grid.template.set("forceHidden", true);
        
        let yAxis1 = chart.yAxes.push(
            am5xy.ValueAxis.new(this.root, {
                renderer: yRenderer1,
                syncWithAxis: yAxis0,
            })
        );

        let columnSeries1 = chart.series.push(
            am5xy.ColumnSeries.new(this.root, {
                name: "Bids",
                xAxis: xAxis,
                yAxis: yAxis0,
                valueYField: String(params.valCol1),
                valueXField: String(params.valXAxis),
                clustered: false,
                tooltip: am5.Tooltip.new(this.root, {
                    pointerOrientation: "horizontal",
                    labelText: "{name}: {valueY}",
                }),
                fill: am5.color(0x920072),
                stroke: am5.color(0x920072),
            })
        );
        
        columnSeries1.columns.template.setAll({
            width: am5.percent(100),
            fillOpacity: 0.5,
            strokeOpacity: 0,
        });

        columnSeries1.data.processor = am5.DataProcessor.new(this.root, {
            dateFields: ["hora"],
            dateFormat: "HH:mm",
        });
        
        let columnSeries0 = chart.series.push(
            am5xy.ColumnSeries.new(this.root, {
                name: "Wins",
                xAxis: xAxis,
                yAxis: yAxis0,
                valueYField: String(params.valCol0),
                valueXField: String(params.valXAxis),
                clustered: false,
                tooltip: am5.Tooltip.new(this.root, {
                    pointerOrientation: "horizontal",
                    labelText: "{name}: {valueY}",
                }),
            })
        );
        
        columnSeries0.columns.template.set("width", am5.percent(100));
    
        let legend = chart.children.push(
            am5.Legend.new(this.root, {
                x: am5.p50,
                centerX: am5.p50,
            })
        );

        legend.data.setAll(chart.series.values);
        
        columnSeries1.data.setAll(params.source);
        columnSeries0.data.setAll(params.source);
        
        this.load(chart);
    }

    async load(chart: am5xy.XYChart) {
		chart.appear();
	}
}

export default new AmchartCombineBulletGraph();