import Vue from "vue";

import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";
import { cloneDeep, difference, isEmpty, isNumber } from "lodash";
import EventListTable from "@/views/Admin/Event/Dependencies/EventListTable/index.vue";
import { ICompactEventInfo } from "@/interfaces/event";
import CardAction from "@/components/Content/CardAction.vue";
import { CardActionType } from "@/interfaces/action";

export default Vue.extend({
	name: "AssociatedEvent",
    props: {
        line_item: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        CardAutocomplete,
        GridTable,
        EventListTable,
        CardAction
    },
	data: () => ({
        resources:{
			events: []
		},
        selectedEvents: [],
        current_line_item: {
            events: []
        },
        selected_all: undefined
    }),
    async mounted() {
        this.updateResources();
        this.resetLine();
    },
    computed: {
        /**
         * Get the list of events
         * @returns list of events
         */
        getEventData() {
			return this.resources.events;
		},

        /**
         * 
         * @returns IDs of the events in this line
         */
        getEventIds(): ICompactEventInfo[] {
            return this.current_line_item?.events ?? [];
        },

        /**
         * Filters the data for listing
         * @returns only applicable events
         */
        getResources() {
            return this.resources.events.filter(item => !this.getEventIds.some(saved_event => saved_event.id === item.id))
        },

        /**
         * Let the buttons to be clickable or not
         */
        isButtonsEnabled() {
            const saved_events = this.line_item.events?.map(item => item.id) || [];
            const current_events = this.current_line_item.events?.map(item => item.id) || [];

            return isEmpty([...difference(current_events, saved_events), difference(saved_events, current_events)].flat());
        },

        /**
         * Verify if all items are selected
         */
        isAllSelected() {
            const selected_all = this.selectedEvents.length === this.getResources.length;
            return selected_all;
        }
    },
    methods: {
        ...mapActions('events', ["all"]),
        ...mapActions('line_item', ["createAssociationWithEvent"]),
		...mapActions("loading", ["setLoadingData"]),

        /**
         * Reset the line item
         */
        async resetLine() {
            this.current_line_item = cloneDeep(this.line_item);
        },

        /**
         * Download resources from database
         */
        async updateResources() {
            const resources = await this.all({
                filters: { 
                    active: true,
                    event_attribution_extra: "WHITELIST",
                    advertiser_id: this.line_item.advertiser_id
                }
            });

            this.resources.events = resources;
        },

        /**
         * Send the event association to the table, joining the old and new data
         */
        async handleSubmitAssociated(){            
            // Cancel if selected events are empty
            if(isEmpty(this.selectedEvents)) return;
            
			await this.handleAddEvents([...this.getEventIds, ...this.selectedEvents])
        },

        /**
         * Delete the event sended from the form
         */
        async handleDeteleEvent(id: number) {
            // Cancel if selected events are empty
            if(!isNumber(id) && id < 1 && isNaN(id)) return;
            
			await this.handleAddEvents(this.getEventIds.filter(event => event.id != id))
        },

        /**
         * Set current events locally
         * @param events Array of events to be showed in table
         */
        async handleAddEvents(events: ICompactEventInfo[] = []) {
            this.current_line_item.events= events.map(item => ({
                active: item.active,
                external_id: item.external_id,
                id: item.id,
                name: item.name
            })) as ICompactEventInfo[];

            
            this.selectedEvents = [];
        },

        /**
         * Send the data to the endpoint
         * @param eventIds Array of event ids to be associated
         */
        async handleSubmitEvents(eventIds: number[] = []) {
            await this.setLoadingData(TypeLoading.loading);
            try {
                const line_item = await this.createAssociationWithEvent({
                    line_item_id: this.current_line_item.id,
                    events_ids: eventIds
                });
                
                // Save the new data of line_item to show new evets associated
                this.$emit("update", line_item);
                await this.setLoadingData();
            } catch(e) {
                await this.setLoadingData();
            }
        },

        /**
         * Save the data in the database
         */
        async handleSave() {
            await this.handleSubmitEvents(this.current_line_item.events.map(item => item.id));
        },

        /**
         * Reset the current line after clear
         */
        handleClear() {
            this.resetLine();
            this.evalAllSelected();
        },

        /**
         * Select all items when click on "Select All"
         */
        handleAllItems() {
            if(this.isAllSelected) {
                return this.selectedEvents = [];
            }

            this.selectedEvents = cloneDeep(this.getResources);
        },

        /**
         * Logic for cancel buttton
         */
        handleCancel() {
            this.$emit("cancel");
        },

        /**
         * Buttons actions handler
         * @param action 
         */
        handleAction(action: { type: CardActionType }) {
            switch(action.type) {
                case CardActionType.DELETE_ALL:
                    this.handleClear();
                    break;
                case CardActionType.CANCEL:
                    this.handleCancel();
                    break;
                case CardActionType.SAVE:
                    this.handleSave();
                    break;
            }
        },

        /**
         * Calculate all selecteds logic
         */
        evalAllSelected() {            
            if(this.isAllSelected) {
                return this.selected_all = [0]
            }

            return this.selected_all = []
        }
    },
    watch: {
        /**
         * Re-save the current value of the EP in the evaluated line item variable
         */
        line_item(val) {
            this.current_line_item = cloneDeep(val);
        },

        /**
         * Selected all logic
         * @param val 
         */
        selectedEvents() {
            this.evalAllSelected()
        }
    }
})