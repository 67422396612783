
import Vue from "vue";
import TargetingTabItemSplit from "./tab_contents/targetingTabItemSplit.vue";

import TabItem from "./tab_items/TabItem.vue";
// @ts-ignore
import { getError } from "@/utils/resolveObjectArray";
import { debounce, isString, isArray, isEmpty } from "lodash";
import { getExcludedKeys } from "../../../../../utils/resolveObjectArray";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";
// @ts-ignore
import { isRequired, isDeal } from "@/services/rule-services";

export default Vue.extend({
  name: "AppSite",
  props: {
    app_site: {
      type: Object,
      default: function () {},
    },
    data_variables: {
      type: Object,
      default: function () {
        return {};
      },
    },
    predicates: {
      type: Object,
      default: function () {
        return {};
      },
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    isCampaignPMP: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TargetingTabItemSplit,
    TabItem,
  },
  data: () => ({
    tab: "app_site",
    appNameTerm: null,
    siteTerm: null,
    placementIdTerm: null,
  }),
  async created() {
    this.$nextTick(async () => {
      setTimeout(async () => {
        await this.requiredResources();
      }, 1000);
    });
  },
  mounted() {},
  computed: {
    getRules() {
      return {
        isRequired,
        isDeal,
      };
    },
    getErrors() {
      return this.$store.state.proccess.errors;
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),

    getError(index: string) {
      return getError(this.getErrors, index);
    },

    async requiredResources() {
      if (!this.is_edit) return;
      const keys = Object.keys(this.app_site);
      keys.forEach(async key => {
        const targeting_terms: Array<any> = this.app_site[key]["targeting_terms"] ?? [];
        if (!isEmpty(targeting_terms)) {
          await this.getComboData(key);
        }
      });
    },

    itemIsArray(item: any) {
      return isArray(item);
    },

    itemIsString(item: any) {
      return isString(item);
    },

    async getComboData(key: string) {
      try {
        if (getExcludedKeys().includes(key)) return;

        if (isEmpty(this.data_variables[key])) {
          this.setLoadingData(TypeLoading.loading);
          this.$emit("update-data-var", {
            tab: this.tab,
            key: key,
            data: await this.dispatchTargetingKey(key),
          });
          await this.setLoadingData();
        }
      } catch (error) {
        console.error("getComboData", { key, error });
        await this.setLoadingData();
      }
    },
    async updateValues(key: string, items: Array<string>) {
      this.$emit("update-item-unique", {
        tab: this.tab,
        key: key,
        items: items,
      });
    },
    adComma(key: any) {
      this.$emit("add-comma", {
        tab: this.tab,
        key: key,
      });
    },
    async dispatchTargetingKey(key: String, object?: any) {
      return this.$store.dispatch("targeting/getTargetingKey", {
        key: key,
        object: object,
      });
    },
    async getAppNameByAtribute(params: { term: String; by_attribute: String }) {
      return this.$store.dispatch("targeting/getAppNameByAtribute", params);
    },
    async getSitesByAtribute(params: { term: String; by_attribute: String }) {
      return this.$store.dispatch("targeting/getSitesByAtribute", params);
    },

    clearHandler(key: any) {
      this.$emit("clear-app-site", { tab: this.tab, key: key });
    },

    syncData(key: String, term: String) {
      switch (key) {
        case "app_name":
          this.appNameTerm = term;
          break;

        case "site":
          this.siteTerm = term;
          break;

        case "placement_id":
          this.placementIdTerm = term;
          break;
      }
    },

    removeHandler(key: any, value: any, is_unique: Boolean = false) {
      this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
        tab: this.tab,
        key: key,
        value: value,
        is_unique: is_unique,
      });
    },

    async updateWatchByKey(key: String, val: Array<any>, old: Array<any>, is_unique: Boolean = false) {
      if (val.length > old.length) {
        const item = val.filter(function (o: any) {
          return !old.includes(o);
        });
        this.$emit(is_unique ? "add-item-unique" : "add-item", {
          tab: this.tab,
          key: key,
          value: item[0],
        });
      }
      if (val.length < old.length) {
        const item = old.filter(function (o: any) {
          return !val.includes(o);
        });
        this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
          tab: this.tab,
          key: key,
          value: item[0],
        });
      }
    },
  },

  watch: {
    async "app_site.app_bundle_list.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("app_bundle_list", val, old);
    },
    async "app_site.app_id_list.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("app_id_list", val, old);
    },
    async "app_site.app_name.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("app_name", val, old, true);
    },
    async "app_site.deal_id_list.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("deal_id_list", val, old);
    },
    async "app_site.domain_list.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("domain_list", val, old);
    },
    async "app_site.placement_list.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("placement_list", val, old, false);
    },
    async "app_site.publisher_id_list.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("publisher_id_list", val, old);
    },
    async "app_site.site.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("site", val, old, true);
    },
    async "app_site.site_list.value"(val: Array<any>, old: Array<any>) {
      await this.updateWatchByKey("site_list", val, old);
    },

    appNameTerm: debounce(async function (val: String, old: String) {
      if (val.length < 3) return;
      this.setLoadingData(TypeLoading.loading);
      this.$emit("update-data-var", {
        tab: this.tab,
        key: "app_name",
        data: await this.getAppNameByAtribute({
          term: val,
          by_attribute: this.app_site.app_name.by_attribute,
        }),
      });

      await this.setLoadingData();
    }, 500),

    siteTerm: debounce(async function (val: String, old: String) {
      if (val.length < 3) return;
      this.setLoadingData(TypeLoading.loading);
      this.$emit("update-data-var", {
        tab: this.tab,
        key: "site",
        data: await this.getSitesByAtribute({
          term: val,
          by_attribute: this.app_site.site.by_attribute,
        }),
      });

      await this.setLoadingData();
    }, 500),

    placementIdTerm: debounce(async function (val: String, old: String) {
      if (val.length < 3) return;
      this.setLoadingData(TypeLoading.loading);
      this.$emit("update-data-var", {
        tab: this.tab,
        key: "placement_id",
        data: await this.getSitesByAtribute({
          term: val,
          by_attribute: this.app_site.placement_id.by_attribute,
        }),
      });

      await this.setLoadingData();
    }, 500),
  },
});
